import React from "react";
import { Link } from "gatsby"
import { CurrentRoute } from './styles'

// display a gatsby fluid image
const UserSystemNavigation = ({currentRoute}) => {

  const items = {
    "Login": "/login",
    "Create new account": "/CreateANewAccount",
    "Forgot your password": "/ForgotYourPassword"
  }

  var counter = 0
  var spacer = ''
  var routeMatched = false

  const navigationItems = Object.keys(items).map(function(key) {
    if (counter > 0) {
      spacer = ' | '
    }
    counter++
    const path = items[key]
    if (currentRoute != path) {
      return (
        <span key={key}>
          {spacer}
          <Link to={path}>{key}</Link>
        </span>
      )
    } else {
      routeMatched = true
      return (
        <>
          {spacer}
          <CurrentRoute>{key}</CurrentRoute>
        </>
      )
    }
  })
  if (routeMatched == false) { 
    throw new Error('route with value: "' + currentRoute +'" could not be matched to a navigation item. Please check the navigation items you provided.')
  }

  return (
    <div>
        {navigationItems}
    </div>
  );
    
}

export default UserSystemNavigation