import styled from "styled-components"
import Form from "react-bootstrap/Form"

export const FormErrors = styled.div`
  margin: 8px 0px;
  padding: 5px;
  border: 1px solid #de2727;
  background-color: #ffcbcb;
  color: #f00
`

export const FormCheckbox = styled(Form.Control)`
  width: 18px;
  height: 17px;
  margin-left: 5px;
  display: inline;
`

export const ExplainerText = styled.div`
  color:        #ccc;
  font-size:    .9em;
`